import React, { FC } from 'react'
import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { media } from 'constants/media/media'

const StyledModalBase = styled.div`
	position: relative;
	width: 53rem;
	max-width: 100vw;
	padding: 6rem 3rem;
	background: #fff;
	box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.1);

	${media.tablet`
		padding: 6rem 6rem;
	`}
`

const StyledCloseIconWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 2rem 2.5rem;
	transition: color 200ms;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.colors.textDark};
	}
`

const ModalBase: FC<{}> = ({ children }) => {
	const dispatch = useDispatch()

	const handleClose = () => dispatch(setActiveModal(''))

	return (
		<StyledModalBase>
			<StyledCloseIconWrapper onClick={handleClose}>
				<FaTimes />
			</StyledCloseIconWrapper>
			{children}
		</StyledModalBase>
	)
}

export default ModalBase
