import React, { FC, useState, ChangeEvent, FormEvent, useRef } from 'react'
import ModalBase from '../modalBase'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import Switch from 'react-switch'
import { H2 } from 'components/base/typography/headings'
import { SwitchLabel, switchStyles, Input } from 'components/base/forms/input'
import { Flex } from 'rebass'
import Button, { StrippedButton } from 'components/base/buttons/buttons'
import { StrongLabel } from 'components/base/forms/label'
import { fetchWithUserAuth, postWithUserAuth } from 'helpers/authHelpers/authHelpers'
import { API_URL } from 'constants/paths/paths'
import { Block } from 'components/base/sections/section'
import { postUserCompanyRelations } from 'reducers/servicePortalReducer'
import { AsyncActionState } from 'interfaces/global/asyncActionState'
import useTypedSelector from 'interfaces/useTypedSelector'
import toastMessage from 'components/utils/toasts'
import { Strong } from 'components/base/typography/misc'

const InviteUserModal: FC<{}> = () => {
	const dispatch = useDispatch()
	const userId = useRef<string>('')
	const [state, setState] = useState<'email' | 'verifying-email' | 'invalid-email' | 'permissions'>('email')
	const [inviteEmailState, setInviteEmailState] = useState<AsyncActionState>('passive')
	const { userInfo } = useTypedSelector(({ auth }) => auth)
	const { activeRelation } = useActiveCompany()
	const [role, setRole] = useState<'Admin' | 'User'>('User')
	const [prices, setPrices] = useState(false)
	const [submitOrders, setSubmitOrders] = useState(false)
	const [orderHistory, setOrderHistory] = useState(false)
	const [email, setEmail] = useState('')

	const handleEmailSubmit = async (e: FormEvent) => {
		e.preventDefault()
		setState('verifying-email')
		try {
			const res = await fetchWithUserAuth(
				`${API_URL}/users/user/exists/${email}?companyId=${activeRelation?.company.id}`
			)
			userId.current = res.data
			setState('permissions')
		} catch (err) {
			setState('invalid-email')
		}
	}

	const handleCloseModal = () => dispatch(setActiveModal(''))

	const handleInviteEmailClick = async () => {
		setInviteEmailState('pending')
		try {
			await postWithUserAuth(`${API_URL}/users/user/invite`, {
				fromName: userInfo?.name,
				email,
				companyName: activeRelation?.company.name,
				relationId: activeRelation?.id,
			})
			setInviteEmailState('fulfilled')
			toastMessage({
				type: 'success',
				message: `${email} hefur verið boðið að stofna aðgang að Matarstræti`,
			})
			setState('email')
		} catch (err) {
			toastMessage({
				type: 'error',
				message: `Eitthvað fór úrskeiðis, tókst ekki að senda ${email} netpóst`,
			})
			setInviteEmailState('failed')
		}
	}

	const handleRole = () => {
		if (role === 'User') {
			setPrices(true)
			setSubmitOrders(true)
			setOrderHistory(true)
		}
		setRole(role === 'Admin' ? 'User' : 'Admin')
	}
	const handleViewPricesChange = () => setPrices(!prices)
	const handleSubmitOrders = () => setSubmitOrders(!submitOrders)
	const handleViewOrderHistory = () => setOrderHistory(!orderHistory)
	const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setState('email')
		setEmail(e.target.value)
	}

	const handleSubmit = async () => {
		dispatch(
			postUserCompanyRelations(userId?.current || '', [
				{
					companyId: activeRelation?.company.id || '',
					role: role,
					canViewPrices: prices,
					canSubmitOrders: submitOrders,
					canSeeOrderHistory: orderHistory,
				},
			])
		)
		dispatch(setActiveModal(''))
	}

	return (
		<ModalBase>
			<H2 mb="4.5rem">
				Bæta við notanda {state === 'permissions' ? email : ''} á {activeRelation?.company.name}
			</H2>
			{state !== 'permissions' && (
				<form onSubmit={handleEmailSubmit}>
					<StrongLabel mb="1.5rem" htmlFor="email">
						Netfang
					</StrongLabel>
					<Input
						type="email"
						id="email"
						name="email"
						aria-label="Settu inn netfang"
						placeholder="Settu inn netfang"
						value={email}
						required
						onChange={handleEmail}
					/>
					{state === 'invalid-email' && (
						<>
							<Block mt="1.5rem" mb="3rem">
								<div>Notandi með þetta netfang fannst ekki.</div>
								<div>Til þess að gefa notanda aðgang að fyrirtæki þarf hann að nýskrá sig inn í kerfið fyrst.</div>
							</Block>
							<Strong mb="1.5rem" mr="0.5rem">
								Viltu bjóða {email} að stofna aðgang að Matarstræti?
							</Strong>
							<StrippedButton
								type="button"
								primary
								fontWeight={400}
								onClick={handleInviteEmailClick}
								disabled={inviteEmailState === 'pending'}>
								Senda netpóst
							</StrippedButton>
						</>
					)}
					<Flex justifyContent="flex-end" mt="5rem">
						<StrippedButton type="button" pl="4rem" pr="4rem" primary fontWeight={400} onClick={handleCloseModal}>
							Hætta við
						</StrippedButton>
						<Button type="submit" secondary disabled={state !== 'email'}>
							Halda áfram
						</Button>
					</Flex>
				</form>
			)}
			{state === 'permissions' && (
				<>
					<SwitchLabel mb="1.5rem" disabled={role === 'Admin'}>
						<Switch {...switchStyles} onChange={handleSubmitOrders} checked={submitOrders} />
						<span>Getur pantað</span>
					</SwitchLabel>
					<SwitchLabel mb="1.5rem" disabled={role === 'Admin'}>
						<Switch {...switchStyles} onChange={handleViewPricesChange} checked={prices} />
						<span>Getur séð verð</span>
					</SwitchLabel>
					<SwitchLabel mb="1.5rem" disabled={role === 'Admin'}>
						<Switch {...switchStyles} onChange={handleViewOrderHistory} checked={orderHistory} />
						<span>Getur séð pantanasögu</span>
					</SwitchLabel>
					<SwitchLabel>
						<Switch {...switchStyles} onChange={handleRole} checked={role === 'Admin'} />
						<span>Admin (hefur aðgang að öllu, ásamt því að geta bætt við notendum)</span>
					</SwitchLabel>
					<Flex justifyContent="flex-end" mt="5rem">
						<StrippedButton type="button" pl="4rem" pr="4rem" primary fontWeight={400} onClick={handleCloseModal}>
							Hætta við
						</StrippedButton>
						<Button secondary onClick={handleSubmit}>
							Vista notanda
						</Button>
					</Flex>
				</>
			)}
		</ModalBase>
	)
}

export default InviteUserModal
