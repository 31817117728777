import styled, { css } from 'styled-components'
import { styleParams } from 'helpers/styleHelpers/styleHelpers'

export const Ul = styled.ul`
	list-style: none;
	padding-inline-start: 3rem;
	${styleParams};
	ul {
		margin: 1rem 0 0 0;
	}
`

export const olbaseStyles = css`
	list-style: none;
	counter-reset: counter;
`
export const Ol = styled.ol`
	padding-inline-start: 3rem;
	${styleParams};

	li {
		margin-bottom: 1rem;

		ol {
			padding-inline-start: 3rem;
			margin: 1rem 0 0 0;
		}
	}
`

export const liBaseStyles = css`
	position: relative;
	margin-bottom: 2.2rem;
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 400;

	&:before {
		content: '';
		position: absolute;
		left: -3rem;
		top: 0.9rem;
		height: 1rem;
		width: 1rem;
		border-radius: 100%;
		background-color: ${props => props.theme.colors.primary};
	}
`

export const Li = styled.li`
	${styleParams};
	${liBaseStyles}
`
