import React, { FC } from 'react'
import ModalBase from '../modalBase'
import { H1 } from 'components/base/typography/headings'
import { P } from 'components/base/typography/misc'

const NoActiveRelation: FC<{}> = () => {
	return (
		<ModalBase>
			<H1 textAlign="center" mb="1.5rem">
				Vinnsla á umsókn í gangi
			</H1>
			<P textAlign="center">Því miður er ekki hægt að panta á meðan umsókn er í vinnslu.</P>
		</ModalBase>
	)
}

export default NoActiveRelation
