import React, { ChangeEvent, FC } from 'react'
import { H3, H4, H5 } from 'components/base/typography/headings'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Checkbox from '../../base/forms/checkbox'
import { useState } from 'react'
import { addProductToList, createProductList, removeProductFromList } from '../../../reducers/productListReducer'
import useTypedSelector from '../../../interfaces/useTypedSelector'
import Button, { StrippedButton } from '../../base/buttons/buttons'
import { Input } from '../../base/forms/input'
import { Flex, Text } from 'rebass'
import IconClose from 'icons/close-large.svg'
import IconCheck from 'icons/check-circled-small.svg'
import IconAdd from 'icons/plus-circled.svg'
import useActiveCompany from '../../../hooks/useActiveCompany'
import { setActiveModal } from 'reducers/modalReducer'
import { StorefrontProductList } from '@local-types/productLists/productList'
import theme from '../../../constants/theme'

const StyledWrapper = styled.div`
	width: 36.2rem;
	max-width: 90%;
	margin: 0 auto;
	padding: 3.5rem;
	background: #fff;
	box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.1);
`

const StyledCheckBox = styled(Checkbox)`
	padding-bottom: 2rem;
	font-weight: 400;
`

const IconAddWrapper = styled.div`
	display: inline-flex;
	height: 100%;
	margin-right: 1.6rem;
	svg {
		width: 2rem;
		margin: 0 !important;
	}
`
const IconCheckWrapper = styled.div`
	display: inline-flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	margin-right: 1.6rem;
	svg {
		display: block;
		width: 2.2rem;
		height: 2.2rem;
		margin: 0 !important;
	}
	svg path {
		fill: ${({ theme }) => theme.colors.white};
	}
`

const StyledNewListWrapper = styled.div``

const ListCheckbox: FC<{ productList: StorefrontProductList }> = ({ productList }) => {
	const { activeProductId } = useTypedSelector(({ productLists }) => productLists)
	const isInList = productList.products?.find(product => product.id === activeProductId) !== undefined
	const [checked, setChecked] = useState(isInList)
	const dispatch = useDispatch()

	const handleListClick = (id: string) => {
		if (!checked) dispatch(addProductToList(id, activeProductId))
		else dispatch(removeProductFromList(id, activeProductId))
		setChecked(!checked)
	}

	return (
		<StyledCheckBox checked={checked} onClick={() => handleListClick(productList.id)}>
			{productList.name}
		</StyledCheckBox>
	)
}

const AddToProductList: FC<{}> = () => {
	const { productLists } = useTypedSelector(({ productLists }) => productLists)
	const [name, setName] = useState('')
	const [showCreateNewlist, setShowCreateNewlist] = useState(false)
	const dispatch = useDispatch()
	const handleName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)
	const { activeRelation } = useActiveCompany()
	const myLists = productLists.filter(l => l.canEditList)

	const onSubmit = () => {
		dispatch(createProductList(name, activeRelation?.company.id))
		setShowCreateNewlist(false)
	}

	return (
		<StyledWrapper>
			{myLists && (
				<Flex justifyContent="space-between" alignItems="center" mb="3rem">
					<H3>Bæta í lista...</H3>
					<StrippedButton iconWidth="2rem" onClick={() => dispatch(setActiveModal(''))}>
						<IconClose />
					</StrippedButton>
				</Flex>
			)}
			{myLists?.map(list => (
				<ListCheckbox key={list.id} productList={list}></ListCheckbox>
			))}
			{showCreateNewlist ? (
				<StyledNewListWrapper>
					<Flex justifyContent="space-between" pb="1rem">
						<H5 mt="1rem">Nafn á lista</H5>
						<StrippedButton iconWidth="2rem" onClick={() => setShowCreateNewlist(false)}>
							<IconClose />
						</StrippedButton>
					</Flex>
					<form onSubmit={onSubmit}>
						<Input type="text" placeholder="Nafn á lista..." onChange={handleName}></Input>
						<Button type="submit" disabled={!name} mt="2rem" block primary>
							Vista nýjan lista
						</Button>
					</form>
				</StyledNewListWrapper>
			) : (
				<>
					<Button mt="2rem" block primary onClick={() => dispatch(setActiveModal(''))}>
						<Flex justifyContent="center" alignItems="center">
							<Text>Vista vörur í lista</Text>
						</Flex>
					</Button>
					<Button mt="1.6rem" block primary noFill onClick={() => setShowCreateNewlist(true)}>
						<Flex justifyContent="center" alignItems="center">
							<Text>Búa til nýjan lista</Text>
						</Flex>
					</Button>
				</>
			)}
		</StyledWrapper>
	)
}

export default AddToProductList
