import React, { FC } from 'react'
import * as StyledModals from './modals.styles'
import { ModalTypes, setActiveModal } from 'reducers/modalReducer'
import useTypedSelector from 'interfaces/useTypedSelector'
import ForgottenPassword from './forgottenPassword'
import { useDispatch } from 'react-redux'
import CompanyPicker from './companyPicker'
import CompanyUserModal from './companyUserModal'
import InviteUserModal from './inviteUserModal'
import NotLoggedIn from './notLoggedIn'
import AddToProductList from './addToProductList/addToProductList'
import CreateProductList from './createProductList'
import NoActiveRelation from './noActiveRelation'
import EditProductList from './editProductList'
import DeleteProductList from './deleteProductList'
import RemoveProductFromProductList from './removeProductFromProductList'
import ModalBase from './modalBase'

type ModalMapper = Record<ModalTypes, FC | null>

const modalMapper: ModalMapper = {
	'forgotten-password': ForgottenPassword,
	'company-picker': CompanyPicker,
	'company-user-modal': CompanyUserModal,
	'invite-user': InviteUserModal,
	'not-logged-in': NotLoggedIn,
	'add-to-product-list': AddToProductList,
	'create-product-list': CreateProductList,
	'edit-product-list': EditProductList,
	'no-active-relation': NoActiveRelation,
	'delete-product-list': DeleteProductList,
	'remove-product-from-list': RemoveProductFromProductList,
	custom: null,
	'': null,
}

const Modals: FC<{}> = () => {
	const dispatch = useDispatch()
	const { activeModal } = useTypedSelector(({ modals }) => modals)
	const Component = modalMapper[activeModal]
	const active = Component !== null

	const handleOverlayClick = () => dispatch(setActiveModal(''))

	if (!active) return null

	return (
		<>
			<StyledModals.Wrapper>{Component !== null && <Component />}</StyledModals.Wrapper>
			<StyledModals.Overlay onClick={handleOverlayClick} />
		</>
	)
}

export const CustomModal: FC<{}> = ({ children }) => {
	const dispatch = useDispatch()
	const { activeModal } = useTypedSelector(({ modals }) => modals)
	const active = activeModal === 'custom'
	const handleOverlayClick = () => dispatch(setActiveModal(''))

	if (!active) return null

	return (
		<>
			<StyledModals.Wrapper>
				<ModalBase>{children}</ModalBase>
			</StyledModals.Wrapper>
			<StyledModals.Overlay onClick={handleOverlayClick} />
		</>
	)
}

export default Modals
