import { createAction, createReducer } from '@reduxjs/toolkit'
import StorefrontUser, { StorefrontCompanyUser } from '@local-types/user/storefrontUser'
import { AppThunk } from 'store'
import { postWithUserAuth, deleteWithUserAuth } from 'helpers/authHelpers/authHelpers'
import { API_URL } from 'constants/paths/paths'
import toastMessage from 'components/utils/toasts'
import { AsyncActionState } from 'interfaces/global/asyncActionState'

type ActiveRelation = null | {
	user: StorefrontUser | null
	relation: StorefrontCompanyUser | null
}

export type UserCompanyRelationRole = 'Admin' | 'User'

interface UserCompanyRelation {
	companyId: string
	role: UserCompanyRelationRole
	canViewPrices: boolean
	canSubmitOrders: boolean
	canSeeOrderHistory: boolean
}

export const setActiveCompanyUserRelation = createAction<ActiveRelation>('set-active-company-user')
export const postUserCompanyRelationsPending = createAction('post-user-company-relations-pending')
export const postUserCompanyRelationsFulfilled = createAction('post-user-company-relations-fulfilled')
export const postUserCompanyRelationsFailed = createAction('post-user-company-relations-failed')
export const postUserCompanyRelations = (
	userId: string,
	relations: UserCompanyRelation[]
): AppThunk => async dispatch => {
	dispatch(postUserCompanyRelationsPending())
	try {
		await postWithUserAuth(`${API_URL}/users/user/${userId}/role?companyId=${relations[0]?.companyId}`, relations)
		dispatch(postUserCompanyRelationsFulfilled())
		toastMessage({
			type: 'success',
			message: 'Réttindi notanda uppfærð',
		})
	} catch (err) {
		dispatch(postUserCompanyRelationsFailed())
		toastMessage({
			type: 'error',
			message: 'Tókst ekki að uppfæra réttindi notanda',
		})
	}
}

export const deleteUserCompanyRelationPending = createAction('delete-user-company-relation-pending')
export const deleteUserCompanyRelationFulfilled = createAction('delete-user-company-relation-fulfilled')
export const deleteUserCompanyRelationFailed = createAction('delete-user-company-relation-failed')
export const deleteUserCompanyRelation = (relationId: string): AppThunk => async dispatch => {
	dispatch(deleteUserCompanyRelationPending())
	try {
		await deleteWithUserAuth(`${API_URL}/users/role`, { relationId })
		dispatch(deleteUserCompanyRelationFulfilled())
		toastMessage({
			type: 'success',
			message: 'Notandi fjarlægður úr fyrirtæki',
		})
	} catch (err) {
		dispatch(deleteUserCompanyRelationFailed())
		toastMessage({
			type: 'error',
			message: 'Tókst ekki að fjarlægja notanda úr fyrirtæki',
		})
	}
}

export interface ServicePortalReducer {
	activeRelation: ActiveRelation
	postUserCompanyRelationsStatus: AsyncActionState
	deleteUserCompanyRelationStatus: AsyncActionState
}

const initialState: ServicePortalReducer = {
	activeRelation: null,
	postUserCompanyRelationsStatus: 'passive',
	deleteUserCompanyRelationStatus: 'passive',
}

const servicePortalReducer = createReducer(initialState, builder =>
	builder
		.addCase(setActiveCompanyUserRelation, (store, action) => {
			store.activeRelation = action.payload
		})
		.addCase(postUserCompanyRelationsPending, store => {
			store.postUserCompanyRelationsStatus = 'pending'
		})
		.addCase(postUserCompanyRelationsFulfilled, store => {
			store.postUserCompanyRelationsStatus = 'fulfilled'
		})
		.addCase(postUserCompanyRelationsFailed, store => {
			store.postUserCompanyRelationsStatus = 'failed'
		})
		.addCase(deleteUserCompanyRelationPending, store => {
			store.deleteUserCompanyRelationStatus = 'pending'
		})
		.addCase(deleteUserCompanyRelationFulfilled, store => {
			store.deleteUserCompanyRelationStatus = 'fulfilled'
		})
		.addCase(deleteUserCompanyRelationFailed, store => {
			store.deleteUserCompanyRelationStatus = 'failed'
		})
)

export default servicePortalReducer
