import styled from 'styled-components'
import { baseHeading } from './headings'
import { liBaseStyles } from './lists'
import { anchorBaseStyles } from './misc'

export const RichTextStyleWrapper = styled.div`
	h1 {
		${baseHeading};
		margin: 4rem 0 2rem;
	}

	h2 {
		${baseHeading};
		margin: 4rem 0 1.5rem;
	}
	p {
		min-height: 1.5rem;
		margin-bottom: 1rem;
	}

	ul {
		list-style: none;
		padding-inline-start: 3rem;
		margin: 3rem 0;
		li {
			${liBaseStyles}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol {
		list-style: none;
		counter-reset: counter;
		li {
			margin-bottom: 2.2rem;
			color: ${({ theme }) => theme.colors.textDark};
			font-weight: 400;
			counter-increment: counter;

			&:before {
				content: counter(counter) '. ';
				color: ${props => props.theme.colors.primary};
			}
		}
	}

	strong {
		font-weight: 700;
		color: ${({ theme }) => theme.colors.textDark};
	}

	a {
		font-weight: 500;
		${anchorBaseStyles}
	}
`
