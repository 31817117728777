import { createAction, createReducer } from '@reduxjs/toolkit'
import { DeliveryTypes } from 'components/checkout/helpers/deliveryTypes'

export interface CheckoutFormData {
	deliveryType: DeliveryTypes
	invoiceExplanation: string
	comment: string
}

export const updateCheckoutData = createAction<{ id: string; value: string }>('update-checkout-data')
export const clearCheckoutData = createAction('clear-checkout-data')

const initialState: CheckoutFormData = {
	deliveryType: DeliveryTypes.DELIVERY,
	invoiceExplanation: '',
	comment: '',
}

const checkoutReducer = createReducer(initialState, builder =>
	builder
		.addCase(updateCheckoutData, (store, action) => {
			return { ...store, [action.payload.id]: action.payload.value }
		})
		.addCase(clearCheckoutData, () => initialState)
)

export default checkoutReducer
