import React, { ChangeEvent, FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import useActiveCompany from '../../../hooks/useActiveCompany'
import { setActiveModal } from '../../../reducers/modalReducer'
import { createProductList } from '../../../reducers/productListReducer'
import Button from '../../base/buttons/buttons'
import { Input } from '../../base/forms/input'
import { H3 } from '../../base/typography/headings'
import ModalBase from '../modalBase'

const CreateProductList: FC<{}> = () => {
	const [name, setName] = useState('')
	const dispatch = useDispatch()
	const handleName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)
	const { activeRelation } = useActiveCompany()

	const onSubmit = () => {
		dispatch(createProductList(name, activeRelation?.company.id))
		dispatch(setActiveModal(''))
	}

	return (
		<ModalBase>
			<H3 mb="3rem" mr="3.5rem">
				Nýr listi
			</H3>
			<form onSubmit={onSubmit}>
				<Input type="text" placeholder="Nafn á lista..." onChange={handleName}></Input>
				<Button type="submit" disabled={!name} mt="3rem" block primary>
					Vista nýjan lista
				</Button>
			</form>
		</ModalBase>
	)
}

export default CreateProductList
