import styled, { css } from 'styled-components'

export const UnorderedList = styled.ul`
	display: block;
	list-style: none;
`

interface UnorderedListItemProps {
	indent?: string
	spacing?: string
}

export const UnorderedListItem = styled.li<UnorderedListItemProps>`
	padding: ${({ spacing }) => (spacing ? spacing : '0.8rem')} ${({ indent }) => (indent ? indent : '2.5rem')};
	margin: 0 -2.5rem;

	&:nth-child(odd) {
		background-color: #fff;
	}

	&:nth-child(even) {
		background-color: ${({ theme }) => theme.colors.grayLighter};
	}
`

interface ListButtonProps {
	active?: boolean
}

export const ListButton = styled.button<ListButtonProps>`
	display: block;
	width: 100%;
	padding: 1.3rem 3.5rem;
	color: ${({ theme }) => theme.colors.textDark};
	background-color: #fff;
	font-family: ${({ theme }) => theme.fonts.fontFamily};
	font-size: 1.8rem;
	font-weight: 400;
	border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	cursor: pointer;
	transition-property: color, background-color, background, border, transform, opacity;
	transition-duration: 150ms;

	&:last-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
	}

	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}

	${({ active, theme }) =>
		active &&
		css`
			background-color: ${theme.colors.grayLightest};
		`}
`
