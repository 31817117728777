import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'constants/keyframes/fades'
import { zIndex } from 'constants/zIndex/zIndex'

const modalEntry = keyframes`
	0% {
		transform: translate3d(-50%, -50%, 0) scale(1.15);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) scale(1);
	}
`

export const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: ${zIndex.modals};
	animation: ${modalEntry} 300ms ease forwards;

	/* z-index fix for iOS Safari */
	transform: translateZ(1px);
	-webkit-transform: translateZ(1px);
`

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${zIndex.modals - 1};
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0;
	animation: ${fadeIn} 300ms ease forwards;
`
