import React, { FC, useState } from 'react'
import Switch from 'react-switch'
import useTypedSelector from 'interfaces/useTypedSelector'
import ModalBase from '../modalBase'
import { H2 } from 'components/base/typography/headings'
import { SwitchLabel, switchStyles } from 'components/base/forms/input'
import { Flex } from 'rebass'
import Button, { StrippedButton } from 'components/base/buttons/buttons'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { postUserCompanyRelations } from 'reducers/servicePortalReducer'

const CompanyUserModal: FC<{}> = () => {
	const dispatch = useDispatch()
	const { activeRelation } = useTypedSelector(({ servicePortal }) => servicePortal)
	const [role, setRole] = useState<'Admin' | 'User'>(activeRelation?.relation?.role || 'User')
	const [prices, setPrices] = useState(!!activeRelation?.relation?.canViewPrices)
	const [submitOrders, setSubmitOrders] = useState(!!activeRelation?.relation?.canSubmitOrders)
	const [orderHistory, setOrderHistory] = useState(!!activeRelation?.relation?.canSeeOrderHistory)

	const handleCloseModal = () => dispatch(setActiveModal(''))

	const handleRole = () => {
		// If the current role is User, we are setting to admin now
		if (role === 'User') {
			setPrices(true)
			setSubmitOrders(true)
			setOrderHistory(true)
		}
		setRole(role === 'Admin' ? 'User' : 'Admin')
	}
	const handleViewPricesChange = () => setPrices(!prices)
	const handleSubmitOrders = () => setSubmitOrders(!submitOrders)
	const handleViewOrderHistory = () => setOrderHistory(!orderHistory)

	const handleSubmit = () => {
		if (!activeRelation || !activeRelation.relation || !activeRelation.user) return
		dispatch(
			postUserCompanyRelations(activeRelation.user.id, [
				{
					companyId: activeRelation.relation.company.id || '',
					role: role === 'Admin' || role === 'User' ? role : 'Admin',
					canViewPrices: prices,
					canSubmitOrders: submitOrders,
					canSeeOrderHistory: orderHistory,
				},
			])
		)
		dispatch(setActiveModal(''))
	}

	return (
		<ModalBase>
			<H2 mb="4.5rem">Uppfæra réttindi notanda</H2>
			<SwitchLabel mt="3rem" mb="1.5rem" disabled={role === 'Admin'}>
				<Switch {...switchStyles} onChange={handleSubmitOrders} checked={submitOrders} />
				<span>Getur pantað</span>
			</SwitchLabel>
			<SwitchLabel mb="1.5rem" disabled={role === 'Admin'}>
				<Switch {...switchStyles} onChange={handleViewPricesChange} checked={prices} />
				<span>Getur séð verð</span>
			</SwitchLabel>
			<SwitchLabel mb="1.5rem" disabled={role === 'Admin'}>
				<Switch {...switchStyles} onChange={handleViewOrderHistory} checked={orderHistory} />
				<span>Getur séð pantanasögu</span>
			</SwitchLabel>
			<SwitchLabel>
				<Switch {...switchStyles} onChange={handleRole} checked={role === 'Admin'} />
				<span>Admin (hefur aðgang að öllu, ásamt því að geta bætt við notendum)</span>
			</SwitchLabel>
			<Flex justifyContent="flex-end" mt="5rem">
				<StrippedButton pl="4rem" pr="4rem" primary fontWeight={400} onClick={handleCloseModal}>
					Hætta við
				</StrippedButton>
				<Button secondary onClick={handleSubmit}>
					Vista notanda
				</Button>
			</Flex>
		</ModalBase>
	)
}

export default CompanyUserModal
