import { createAction, createReducer } from '@reduxjs/toolkit'

export const setLoginMenuState = createAction<boolean>('set-login-menu-state')

export interface HeaderReducer {
	loginMenuOpen: boolean
}

const initialState: HeaderReducer = {
	loginMenuOpen: false,
}

const headerReducer = createReducer(initialState, builder =>
	builder.addCase(setLoginMenuState, (store, action) => {
		store.loginMenuOpen = action.payload
	})
)

export default headerReducer
