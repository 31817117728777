import React, { FC } from 'react'
import ModalBase from '../modalBase'
import { H1 } from 'components/base/typography/headings'
import { Button } from 'components/base/buttons/buttons'
import { setActiveModal } from '../../../reducers/modalReducer'
import { useDispatch } from 'react-redux'
import { Flex } from 'rebass'
import { P } from 'components/base/typography/misc'
import useTypedSelector from '../../../interfaces/useTypedSelector'
import { deleteProductList, setActiveProductList } from '../../../reducers/productListReducer'

const DeleteProductList: FC<{}> = () => {
	const dispatch = useDispatch()
	const { activeList } = useTypedSelector(({ productLists }) => productLists)

	if (!activeList) return null

	return (
		<ModalBase>
			<H1 textAlign="center" mb="4rem">
				Eyða lista
			</H1>
			<P textAlign="center">Ertu viss um að þú viljir eyða vörulista?</P>
			<Flex justifyContent="space-between" mt="4rem">
				<Button
					primary
					noFill
					onClick={() => {
						dispatch(setActiveModal(''))
					}}>
					Hætta við
				</Button>
				<Button
					secondary
					onClick={() => {
						dispatch(deleteProductList(activeList.id))
						dispatch(setActiveProductList(null))
						dispatch(setActiveModal(''))
					}}>
					Eyða lista
				</Button>
			</Flex>
		</ModalBase>
	)
}

export default DeleteProductList
