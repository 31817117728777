import React, { ChangeEvent, FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import useTypedSelector from '../../../interfaces/useTypedSelector'
import { setActiveModal } from '../../../reducers/modalReducer'
import { editProductList } from '../../../reducers/productListReducer'
import Button from '../../base/buttons/buttons'
import { Input } from '../../base/forms/input'
import { H3 } from '../../base/typography/headings'
import ModalBase from '../modalBase'

const EditProductList: FC<{}> = () => {
	const dispatch = useDispatch()
	const { activeList } = useTypedSelector(({ productLists }) => productLists)
	const [name, setName] = useState(activeList?.name || '')
	const handleName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)

	const onSubmit = () => {
		if (activeList) dispatch(editProductList(activeList.id, name))
		dispatch(setActiveModal(''))
	}

	return (
		<ModalBase>
			<H3 mb="3rem" mr="3.5rem">
				Breyta nafni á lista
			</H3>
			<form onSubmit={onSubmit}>
				<Input type="text" value={name} onChange={handleName}></Input>
				<Button disabled={!name} type="submit" block mt="3rem" primary>
					Vista nafn
				</Button>
			</form>
		</ModalBase>
	)
}

export default EditProductList
