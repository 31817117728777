import React, { FC } from 'react'
import ModalBase from '../modalBase'
import { H1 } from 'components/base/typography/headings'
import { Button } from 'components/base/buttons/buttons'
import { setActiveModal } from '../../../reducers/modalReducer'
import { useDispatch } from 'react-redux'
import { Flex } from 'rebass'
import { P } from 'components/base/typography/misc'
import useTypedSelector from '../../../interfaces/useTypedSelector'
import { removeProductFromList, setActiveProductList } from 'reducers/productListReducer'
import { StorefrontProductList } from '@local-types/productLists/productList'

const RemoveProductFromProductList: FC<{}> = () => {
	const dispatch = useDispatch()
	const { activeList, activeProductId } = useTypedSelector(({ productLists }) => productLists)

	if (!activeList) return null

	return (
		<ModalBase>
			<H1 textAlign="center" mb="4rem">
				Fjarlægja vöru
			</H1>
			<P textAlign="center">Ertu viss um að þú viljir fjarlægja vöru úr lista?</P>
			<Flex justifyContent="space-between" mt="4rem">
				<Button
					primary
					noFill
					onClick={() => {
						dispatch(setActiveModal(''))
					}}>
					Hætta við
				</Button>
				<Button
					secondary
					onClick={() => {
						dispatch(removeProductFromList(activeList.id, activeProductId))
						const list: StorefrontProductList = {
							id: activeList.id,
							name: activeList.name,
							products: activeList.products.filter(p => p.id !== activeProductId),
							canEditList: activeList.canEditList,
						}
						dispatch(setActiveProductList(list))
						dispatch(setActiveModal(''))
					}}>
					Fjarlægja vöru
				</Button>
			</Flex>
		</ModalBase>
	)
}

export default RemoveProductFromProductList
