import React, { FC, useState, ChangeEvent, FormEvent } from 'react'
import ModalBase from '../modalBase'
import { H1 } from 'components/base/typography/headings'
import styled from 'styled-components'
import { P, PSmall } from 'components/base/typography/misc'
import { StrongLabel } from 'components/base/forms/label'
import { Input } from 'components/base/forms/input'
import Button from 'components/base/buttons/buttons'
import { Auth } from 'aws-amplify'
import toastMessage from 'components/utils/toasts/toasts'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { signupErrorMsgMapper } from 'components/forms/signupForm/userInfoForm/signupErrorMsgMapper'

const StyledIntro = styled.div`
	text-align: center;
`

type FormState = 'initial' | 'sending-code' | 'awaiting-confirmation' | 'sending-confirmation' | 'fulfilled'

const ForgottenPassword: FC<{}> = () => {
	const dispatch = useDispatch()
	const [username, setUsername] = useState('')
	const [code, setCode] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [formState, setFormState] = useState<FormState>('initial')

	const handleUsername = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
	const handleCode = (e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)
	const handleNewPassword = (e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)

	const sendCode = async () => {
		setFormState('sending-code')

		try {
			const res = await Auth.forgotPassword(username)
			if (res) setFormState('awaiting-confirmation')
		} catch (err) {
			setFormState('initial')
			toastMessage({
				type: 'error',
				message: 'Tókst ekki að senda kóða á netfang',
			})
		}
	}

	const sendConfirmation = async () => {
		setFormState('sending-confirmation')

		try {
			await Auth.forgotPasswordSubmit(username, code, newPassword)
			dispatch(setActiveModal(''))
			toastMessage({
				type: 'success',
				message: 'Lykilorði þínu hefur verið breytt',
			})
		} catch (err) {
			setFormState('awaiting-confirmation')
			toastMessage({
				type: 'error',
				// @ts-ignore
				message: signupErrorMsgMapper[err.message] || err.message,
			})
		}
	}

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()
		if (username.length === 0 || formState === 'sending-code') return
		if (formState === 'initial') sendCode()
		if (formState === 'awaiting-confirmation') sendConfirmation()
	}

	const step1Active = formState === 'initial' || formState === 'sending-code'
	const step2Active = formState === 'awaiting-confirmation' || formState === 'sending-confirmation'

	return (
		<ModalBase>
			<StyledIntro>
				<H1>Gleymt lykilorð</H1>
				<P mt="3rem" mb="4rem">
					{step1Active &&
						`
						Vinsamlegast settu inn netfangið þitt og
						við sendum þér staðfestingarkóða í tölvupósti.
					`}
					{step2Active &&
						`
						Vinsamlegast settu inn staðfestingarkóðann sem barst þér á
						netfangið þitt og sláðu inn nýtt lykilorð.
					`}
				</P>
			</StyledIntro>
			<form onSubmit={handleSubmit}>
				{step1Active && (
					<>
						<StrongLabel mb="1rem" htmlFor="username">
							Netfang
						</StrongLabel>
						<Input
							type="text"
							id="username"
							name="username"
							aria-label="username"
							placeholder="Settu inn netfang"
							value={username}
							onChange={handleUsername}
						/>
					</>
				)}
				{step2Active && (
					<>
						<StrongLabel mb="1rem" htmlFor="confirmation-code">
							Staðfestingarkóði
						</StrongLabel>
						<Input
							type="text"
							id="confirmation-code"
							name="confirmation-code"
							aria-label="confirmation-code"
							placeholder="Staðfestingarkóði"
							autoComplete="new-password"
							value={code}
							onChange={handleCode}
						/>
						<StrongLabel mt="1.5rem" mb="1rem" htmlFor="new-password">
							Nýtt lykilorð
						</StrongLabel>
						<Input
							type="password"
							id="new-password"
							name="new-password"
							aria-label="new-password"
							autoComplete="new-password"
							placeholder="Nýtt lykilorð"
							value={newPassword}
							onChange={handleNewPassword}
						/>
						<PSmall mt="0.5rem">Minnst átta stafir, stór stafur, lítill stafur, tölustafur.</PSmall>
					</>
				)}
				<Button
					primary
					block
					mt="1.5rem"
					isLoading={formState === 'sending-code' || formState === 'sending-confirmation'}>
					Senda
				</Button>
			</form>
		</ModalBase>
	)
}

export default ForgottenPassword
