import React, { FC, MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { FaCheck } from 'react-icons/fa'

interface Props {
	id?: string | undefined
	checked: boolean
	onClick: (event: MouseEvent<HTMLDivElement>) => void
	value?: string
	round?: boolean
}

const StyledCheck = styled(FaCheck)`
	opacity: 0;
	color: ${({ theme }) => theme.colors.borderColor};
	transform: scale(0.6);
	transition: opacity 250ms, color 250ms, transform 250ms;
`

const StyledCheckbox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
	background-color: #fff;
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	font-size: 1.2rem;
	margin-right: 1.4rem;
	transition: border-color 250ms, background-color 250ms;
	border-radius: 4px;
`

interface WrapperProps {
	checked: boolean
	round?: boolean
}

const StyledWrapper = styled.div<WrapperProps>`
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		${StyledCheck} {
			opacity: 1;
		}
	}

	${({ checked }) =>
		checked &&
		css`
			${StyledCheckbox} {
				background-color: ${({ theme }) => theme.colors.primary};
			}

			${StyledCheck} {
				opacity: 1;
				color: #fff;
				transform: scale(1);
			}

			&:hover {
				${StyledCheck} {
					opacity: 1;
				}
			}
		`};

	${({ round }) =>
		round &&
		css`
			align-items: flex-start;

			${StyledCheckbox} {
				border-radius: 50%;
				border: none;
				width: 28px;
				height: 28px;
			}

			${StyledCheck} {
				color: ${({ theme }) => theme.colors.white};
				transform: scale(1.2);
			}
		`}

	${({ checked, round }) =>
		checked &&
		round &&
		css`
			${StyledCheckbox} {
				background-color: ${({ theme }) => theme.colors.green};
			}
		`}
`

const Checkbox: FC<Props> = ({ checked, round, children, ...props }) => {
	return (
		<StyledWrapper {...props} round={round} checked={checked}>
			<StyledCheckbox tabIndex={0}>
				<StyledCheck />
			</StyledCheckbox>
			{children}
		</StyledWrapper>
	)
}

export default Checkbox
