import React, { FC } from 'react'
import { H3 } from 'components/base/typography/headings'
import useTypedSelector from 'interfaces/useTypedSelector'
import { ListButton } from 'components/base/lists/lists'
import styled from 'styled-components'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { P } from 'components/base/typography/misc'
import { setActiveProductList } from '../../../reducers/productListReducer'

const StyledWrapper = styled.div`
	width: 36.2rem;
	max-width: 90%;
	margin: 0 auto;
	padding-top: 3.5rem;
	background: #fff;
	box-shadow: 0px 10px 34px rgba(0, 0, 0, 0.1);
`

const CompanyPicker: FC<{}> = () => {
	const dispatch = useDispatch()
	const { userInfo, userInfoState } = useTypedSelector(({ auth }) => auth)
	const { activeRelation, setRelation } = useActiveCompany()

	const handleRelationClick = (id: string) => {
		setRelation(id)
		dispatch(setActiveProductList(null))
		dispatch(setActiveModal(''))
	}

	// TODO: Better message for no relations

	return (
		<StyledWrapper>
			<H3 mb="3rem" ml="3.5rem" mr="3.5rem">
				Veldu aðra kennitölu
			</H3>
			{userInfo?.companyUsers.map(relation => (
				<ListButton
					key={relation.id}
					active={activeRelation?.id === relation.id}
					onClick={handleRelationClick.bind(null, relation.id)}>
					{relation.company.name}
				</ListButton>
			))}
			{userInfoState === 'fulfilled' && userInfo && userInfo.companyUsers.length === 0 && (
				<P ml="3.5rem" mr="3.5rem" pb="5rem">
					Þú virðist ekki hafa aðgang að neinum kennitölum ennþá
				</P>
			)}
		</StyledWrapper>
	)
}

export default CompanyPicker
