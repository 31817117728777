import React, { FC } from 'react'
import ModalBase from '../modalBase'
import styled from 'styled-components'
import { Flex } from 'rebass'
import { H1, H6 } from 'components/base/typography/headings'
import { P } from 'components/base/typography/misc'
import Button from 'components/base/buttons/buttons'
import { useDispatch } from 'react-redux'
import { setActiveModal } from 'reducers/modalReducer'
import { setLoginMenuState } from 'reducers/headerReducer'

const SignupLink = styled.a`
	color: ${({ theme }) => theme.colors.secondary};
	text-decoration: underline;
	text-align: center;
`

const NotLoggedIn: FC<{}> = () => {
	const dispatch = useDispatch()

	const handleLoginClick = () => {
		dispatch(setActiveModal(''))
		dispatch(setLoginMenuState(true))
	}

	return (
		<ModalBase>
			<H1 textAlign="center" mb="1.5rem">
				Þú ert ekki innskráður
			</H1>
			<P textAlign="center">Þú þarft að vera innskráður til að geta verslað vörur. Vinsamlegast skráðu þig inn.</P>
			<Button onClick={handleLoginClick} primary block mt="3rem" mb="3rem">
				Innskrá
			</Button>
			<Flex flexDirection="column" alignItems="center">
				<H6 textAlign="center">Ekki búinn að búa til aðgang?</H6>
				<SignupLink href="/nyskraning">Nýskráning</SignupLink>
			</Flex>
		</ModalBase>
	)
}

export default NotLoggedIn
