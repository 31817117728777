import styled from 'styled-components'
import { styleParams } from 'helpers/styleHelpers/styleHelpers'

export const Section = styled.section`
	${styleParams};
`
export const Block = styled.div`
	${styleParams};
`
export const InlineBlock = styled.div`
	display: inline-block;
	${styleParams};
`
